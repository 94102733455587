@import "../../scss/Utils";

.login {
  width: 370px;
  padding: 40px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 8px -8px black;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  @include centralize-absolute;

  &_form {
    margin-top: 20px;
    flex: 1;
  }

  &_label {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 13px;
  }

  &_input {
    border: 1px solid #c3c3c3;
    width: 100%;
    padding: 5px 5px;
    border-radius: 5px;
    transition: all 300ms ease-in-out;
    
    &:focus-visible {
      outline: none;
    }

    @include box-shadow(#5c4fa1, 1px);

    &:focus {
      border-color: #5c4fa1;
    }
  }

  &__primary_btn {
    @include reset-btn;
    background-color: #5c4fa1;
    color: white;
    padding: 7px 15px;
    font-size: 0.8em;
    border-radius: 5px;
    margin-top: 15px;
    flex: 1;
    align-items: flex-end;
  }
}

.header {

  flex-direction: column;
  
  
}

.title {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  margin-top: 15px;
}

.ldsellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 20px;
}

.ldsellipsis div {
  position: absolute;
  top: 5px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.ldsellipsis div:nth-child(1) {
  left: 8px;
  animation: ldsellipsis1 0.6s infinite;
}
.ldsellipsis div:nth-child(2) {
  left: 8px;
  animation: ldsellipsis2 0.6s infinite;
}
.ldsellipsis div:nth-child(3) {
  left: 32px;
  animation: ldsellipsis2 0.6s infinite;
}
.ldsellipsis div:nth-child(4) {
  left: 56px;
  animation: ldsellipsis3 0.6s infinite;
}
@keyframes ldsellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes ldsellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes ldsellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
