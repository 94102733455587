@import "modern-css-reset";
@import 'roboto-fontface';
@import "react-notifications/dist/react-notifications.css";

html, body, #root {
  height: 100%;
  width: 100%;
  max-height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'sans-serif';
}

body {
  background-color: #ebebeb;
  position: relative;
}

#root {
  display: flex;
  flex-direction: row;
}
