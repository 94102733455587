@mixin reset-btn {
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

@mixin reset-list {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;

  & > li {
    display: inline-block;
  }
}


@mixin btn-effect-no-shadow($effectColor) {
  &:hover, &:active, &--active, &:focus {
    background-color: $effectColor;
  }
}

@mixin box-shadow($shadowColor, $size) {
  &:focus {
    box-shadow: 0 0 0 $size $shadowColor;
  }
}

@mixin btn-effect($effectColor, $shadowColor) {
  &:hover, &:active, &--active, &:focus {
    background-color: $effectColor;
  }

  @include box-shadow($shadowColor, .2rem);
}

@mixin scrollbar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000000;
  }
}

@mixin ellipse-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin centralize-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
